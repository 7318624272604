body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn-main{
  background-color: #28c6be;
  transition-duration: .2s;
  color: white;
}
.btn-main:hover{
  background-color: #27b3ac;
  color: white;
}
.btn-outline-main{
  color: #28c6be;
  border: 1px solid #28c6be;
  transition-duration: .2s;
}
.btn-outline-main:hover{
  background-color: #28c6be;
  color: white;
}
.text-second{
  color: #858585;
}
.text-main{
  color: #28c6be;
}
.border-main{
  border-color: #28c6be !important;
}
.foo-hover:hover{
  color: white;
}
.rounded-20{
  border-radius: 20px;
}
.box:hover{
  box-shadow: 5px 5px 15px #949494 !important;
  transition-duration: .2s;
}